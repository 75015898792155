<template>
  <div v-if="visible" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div class="bg-white rounded-lg shadow-lg p-6 w-96">
      <h2 class="text-xl font-semibold text-red-600 mb-4">Error</h2>
      <p class="text-gray-800 mb-4">{{ message }}</p>
      <button
        @click="hide"
        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      onCloseCallback: null
    };
  },
  methods: {
    show(message) {
      this.message = message;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.message = '';
      if (this.onCloseCallback) {
        this.onCloseCallback();
        this.onCloseCallback = null;
      }
    },
  },
};
</script>
  