import { Vue, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import clickOutside from './directives/clickOutside'
import Loader from './services/LoaderOverlay';

// import main css
// import './css/main.css'
import '/index.css'
import store from './store';  // <-- Make sure this import is correct

axios.defaults.baseURL = process.env.VUE_APP_APIURL;
// In your main.js or a dedicated Axios configuration file

// Request interceptor to set the Authorization header dynamically
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Setup Axios interceptor for handling unauthenticated responses
axios.interceptors.response.use(response => {
  // Just return the response if it's successful
  return response;
}, error => {
  const appInstance = document.getElementById('app')?.__vue_app__;
  if (appInstance) {
    const errorModal = appInstance._instance?.refs?.errorModal;
    if (errorModal && error.response && error.response.data) {
      console.log('Outside 401');
      if (error.response.status === 401) {
        errorModal.show(error.response.data.message || 'Your session has expired. Please log in again.');
        console.log('Outside close');
        errorModal.onCloseCallback = () => {
          // Clear local storage and redirect after the modal is closed
          localStorage.removeItem('token');
          localStorage.removeItem('darkmode');
          localStorage.removeItem('user_id');
          localStorage.removeItem('internal');
          router.push({ name: 'login' }).then(() => {
            window.location.reload();
          });
        };
      } else {
        errorModal.show(error.response.data.message || 'An error occurred.');
      }
    }
  }
  return Promise.reject(error);
});


const app = createApp(App);
app.directive('click-outside', clickOutside);
app.use(store);
app.use(router);
app.use(Loader);
app.mount('#app');